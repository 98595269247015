import * as React from 'react';
// import { Popover, Transition } from '@headlessui/react';
// import {
//   MenuIcon,
//   QuestionMarkCircleIcon,
//   SearchIcon,
//   ShoppingBagIcon,
// } from '@heroicons/react/outline';

interface HeaderProps {}

// const navigation = {
//   pages: [
//     { href: '#', name: 'Breakfast' },
//     { href: '#', name: 'Lunch' },
//     { href: '#', name: 'Dinner' },
//   ],
// };

/**
 * @name Header
 * @description tbd...
 */
const Header: React.FC<HeaderProps> = (_props) => {
  return (
    <header className="relative z-10">
      <nav aria-label="Top">
        {/* Secondary navigation */}
        <div className="backdrop-blur-md backdrop-filter bg-opacity-10 bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div>
              <div className="h-16 flex items-center justify-center">
                {/* Logo (lg+) */}
                {/* <div className="hidden lg:flex-1 lg:flex lg:items-center">
                  <a href="#">
                    <span className="sr-only">Workflow</span>
                    <img className="h-8 w-auto" src="/images/logo.png" alt="" />
                  </a>
                </div> */}

                {/* <div className="hidden h-full lg:flex">
                  <div className="h-full flex justify-center space-x-8">
                    {navigation.pages.map((page) => (
                      <a
                        key={page.name}
                        href={page.href}
                        className="flex items-center text-sm font-medium text-white"
                      >
                        {page.name}
                      </a>
                    ))}
                  </div>
                </div> */}

                {/* Mobile menu and search (lg-) */}
                {/* <div className="flex-1 flex items-center lg:hidden">
                  <button
                    type="button"
                    className="-ml-2 p-2 text-white"
                    onClick={() => setMobileMenuOpen(true)}
                  >
                    <span className="sr-only">Open menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  <a href="#" className="ml-2 p-2 text-white">
                    <span className="sr-only">Search</span>
                    <SearchIcon className="w-6 h-6" aria-hidden="true" />
                  </a>
                </div> */}

                {/* Logo (lg-) */}
                <a href="/" className="">
                  <span className="sr-only">Workflow</span>
                  <img src="/images/logo.png" alt="" className="h-8 w-auto" />
                </a>

                {/* <div className="flex-1 flex items-center justify-end">
                  <a
                    href="#"
                    className="hidden text-sm font-medium text-white lg:block"
                  >
                    Search
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export { Header };
export type { HeaderProps };
