import * as React from 'react';
import type { GetServerSidePropsResult, NextPage } from 'next';

import { Recipe } from '@src/firebase/types';
import { FAQs } from '@src/routes/home/components/FAQs';
import { Featured } from '@src/routes/home/components/Featured';
import { Hero } from '@src/routes/home/components/Hero';
import { getFeaturedRecipes } from '@src/routes/home/firestore/get-featured-recipes';

interface HomeProps {
  recipes: Recipe[];
}

const Home: NextPage<HomeProps> = (props) => {
  const { recipes } = props;

  return (
    <div>
      <Hero />

      <div>
        <Featured recipes={recipes} />
        <FAQs />
      </div>
    </div>
  );
};

export async function getServerSideProps(): Promise<
  GetServerSidePropsResult<HomeProps>
> {
  return {
    props: {
      recipes: await getFeaturedRecipes(),
    },
  };
}

export default Home;
