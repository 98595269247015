import * as React from 'react';

interface FAQsProps {}

const faqs = [
  {
    answer:
      'There are many great reasons! Our main reason are: It helps the environment immensely, no animals have to suffer, it is the best diet for your health, it helps prevent global pandemics.',
    id: 1,
    question: 'Why eat plant based?',
  },
  {
    answer:
      "Yes, as long as you eat a variety of food, you'll get all the protein you need. Black beans have just as much protein as a steak with none of the fat and all the fiber!",
    id: 2,
    question: 'Can I still get enough protein?',
  },
  {
    answer:
      "There are  studies that show as your microbiome changes, you actually crave the foods you eat regularly. It doesn't take long to start loving plant based food. Give yourself an honest try for a month and you will start to crave the deliciousness of plants!",
    id: 3,
    question: "Don't you get tired of eating rabbit food?",
  },
  {
    answer:
      'There are plenty of plant-based products that mimic meat. Prepared the right way, they can definitely satisfy that craving for meat! Brands we recommend include Impossible, Beyond, Field Roast and Quorn.',
    id: 4,
    question: 'What if I miss meat?',
  },
  {
    answer:
      "As with meat, the plant-based industry has made some amazing strides in mimicking egg products. Try JUST Egg to replace scrambled eggs, omelets, frittatas. Bob's Mill Egg Replacer works wonders for baking.",
    id: 5,
    question: 'What if I miss eggs?',
  },
  {
    answer:
      'You guessed it - there are a bunch of plant-based subs out there. Soy Milk and Soy Creamer is a staple of ours (just make sure you get unsweetened for dairy milks if you want to keep it on the healthy side). Smoke Gouda is great for grilled cheeses, Daiya makes a good shredded mozzarella and Tree Line is our fav cream cheese substitute.',
    id: 6,
    question: 'What if I miss dairy?',
  },
];

/**
 * @name FAQs
 * @description Displays the FAQs on the home page.
 */
const FAQs: React.FC<FAQsProps> = (_props) => {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="max-w-2xl lg:mx-auto lg:text-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Frequently asked questions
          </h2>
          <p className="mt-4 text-gray-500">
            We know there are many great questions about getting started with
            plant-based food. Here are some of the most common questions we
            hear.
          </p>
        </div>
        <div className="mt-20">
          <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10">
            {faqs.map((faq) => (
              <div key={faq.id}>
                <dt className="font-semibold text-gray-900">{faq.question}</dt>
                <dd className="mt-3 text-gray-500">{faq.answer}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export { FAQs };
export type { FAQsProps };
