import * as React from 'react';

import { routes } from '@src/config/constants';
import { Recipe } from '@src/firebase/types';

interface FeaturedProps {
  recipes: Recipe[];
}

/**
 * @name Featured
 * @description tbd...
 */
const Featured: React.FC<FeaturedProps> = (props) => {
  const { recipes } = props;

  return (
    <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            Featured Recipes
          </h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            Here are a few of our favorite recipes!
          </p>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {recipes.map((recipe) => (
            <div
              key={recipe.name}
              className="flex flex-col rounded-lg shadow-lg overflow-hidden"
            >
              <a href={`${routes.view}/${recipe.friendlyUrl}`} className="">
                <div className="flex-shrink-0">
                  <img
                    className="h-72 w-full object-cover"
                    src={recipe.imageUrl}
                    alt=""
                  />
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between h-full">
                  <div className="flex-1">
                    <p className="text-xl font-semibold text-gray-900">
                      {recipe.name}
                    </p>
                    <p className="mt-3 text-base text-gray-500">
                      {recipe.description}
                    </p>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export { Featured };
export type { FeaturedProps };
