import * as React from 'react';
// import { Dialog, Tab, Transition } from '@headlessui/react';
// import { XIcon } from '@heroicons/react/outline';
// import classnames from 'classnames';
import Image from 'next/image';

import { Header } from '@src/routes/all/components/Header/Header';

interface HeroProps {
  className?: string;
}

/**
 * @name Hero
 * @description tbd...
 */
const Hero: React.FC<HeroProps> = (_props) => {
  // Hooks

  // Setup

  // Styles

  // Handlers

  // Markup

  // Life Cycle

  // Short Circuit

  return (
    <div className="bg-white">
      {/* Mobile menu TODO gavalas */}

      {/* Hero section */}
      <div className="relative bg-gray-900">
        {/* Decorative image and overlay */}
        <div aria-hidden="true" className="absolute inset-0 overflow-hidden">
          <Image
            src="/images/bowl-of-vegetables.jpg"
            className="w-full h-full object-center object-cover"
            layout="fill"
          />
          {/* <img
          src="https://tailwindui.com/img/ecommerce-images/home-page-01-hero-full-width.jpg"
          alt=""
          className="w-full h-full object-center object-cover"
        /> */}
        </div>
        <div
          aria-hidden="true"
          className="absolute inset-0 bg-gray-900 opacity-50"
        />

        {/* Navigation */}
        <Header />

        <div className="relative max-w-3xl mx-auto py-32 px-6 flex flex-col items-center text-center sm:py-64 lg:px-0">
          <h1 className="text-4xl font-extrabold tracking-tight text-white lg:text-6xl">
            Plant-based recipes for all
          </h1>
          <p className="mt-4 text-xl text-white">
            We made this website specifically for our friends and family (like
            you!) to share in our collection of plant based recipes that we love
            to cook and eat. Bon appetit!
          </p>
          <a
            href="/recipes"
            className="mt-8 inline-block border border-transparent rounded-md py-3 px-8 text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Recipes
          </a>
        </div>
      </div>
    </div>
  );
};

export { Hero };
export type { HeroProps };
